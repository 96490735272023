import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { RoutingMap } from '../models/RoutingMap';
import * as routingData from './routingMap.json';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      console.log('uugfuafa', url);
      let newRoute = '';

      url.forEach(segment => {
        newRoute = newRoute + segment.path + '/';
      });

      newRoute = newRoute.replace(/\/\s*$/, '');

      let newPath = generatePath(newRoute);

      this.route.queryParams.subscribe(queryParams => {
        const params = generateQueryParams(queryParams);

        if (params !== '') {
          newPath = newPath + '?' +  params;

          const index = newPath.lastIndexOf('&');
          newPath = newPath.substring(0, index) + newPath.substring(index + 1);
        }
      });

      newPath = 'https://www.rockethomes.com/' + newPath;

      window.location.href = newPath;
    });
  }
}

function generatePath(path: string): string {
  let redirectPath = 'credit-score?qlsource=rhqdomain';
  const rawRoutingMap: any = (routingData as any).default;
  const routingMap: RoutingMap = rawRoutingMap;

  routingMap.routes.forEach(route => {
    if (route.from === path) {
      redirectPath = route.to;
      return;
    }
  });

  return redirectPath;
}

function generateQueryParams(queryParams: any): string {
  let params = '';
  for (const [key, value] of Object.entries(queryParams)) {
    params = params + key + '=' + value + '&';
  }

  return params;
}

// function generatePath(path: string): string {
//   switch (path) {
//     case 'learn/credit/12-credit-myths-you-should-stop-believing':
//       return 'credit-guide/12-credit-myths-you-should-stop-believing';
//     case 'learn/credit/length-of-credit-history':
//       return 'credit-guide/credit-score-factors-length-of-credit-history';
//     case 'learn/credit/credit-score-factors-new-credit':
//       return 'credit-guide/credit-score-factors-new-credit';
//     case 'learn/credit/hard-vs-soft-credit-pull':
//       return 'credit-guide/hard-vs-soft-credit-pull';
//     case 'learn/credit/how-do-i-remove-errors-from-my-credit-report':
//       return 'credit-guide/how-do-i-remove-errors-from-my-credit-report';
//     case 'learn/credit/how-long-does-it-take-to-build-credit':
//       return 'credit-guide/how-long-does-it-take-to-build-credit';
//     case 'learn/credit/how-many-lines-of-credit-should-i-have':
//       return 'credit-guide/how-many-lines-of-credit-should-i-have';
//     case 'learn/credit/how-often-check-credit-score':
//       return 'credit-guide/how-many-times-can-i-check-my-credit';
//     case 'learn/credit/how-to-build-credit':
//       return 'credit-guide/building-credit-for-the-first-time';
//     case 'learn/credit/how-to-improve-credit-score':
//       return 'credit-guide/how-to-improve-your-credit-score-8-tips';
//     case 'learn/credit/how-to-remove-late-payments-with-a-goodwill-letter':
//       return 'credit-guide/how-to-remove-late-payments-with-a-goodwill-letter';
//     case 'learn/credit/is-it-bad-to-cancel-a-credit-card':
//       return 'credit-guide/is-it-bad-to-cancel-a-credit-card';
//     case 'learn/credit/revolving-credit':
//       return 'credit-guide/revolving-credit-what-is-it-and-how-does-it-work';
//     case 'learn/credit/what-lowers-your-credit-score':
//       return 'credit-guide/things-that-can-lower-your-credit-score-and-how-to-avoid-them';
//     case 'learn/credit/understanding-the-differences-between-fico-and-vantagescore':
//       return 'credit-guide/understanding-the-differences-between-fico-and-vantagescore';
//     case 'learn/credit/what-hurts-my-credit-score-the-most':
//       return 'credit-guide/what-hurts-my-credit-score-the-most';
//     case 'learn/credit/what-is-a-credit-freeze':
//       return 'credit-guide/what-is-a-credit-freeze';
//     case 'learn/credit/what-is-a-credit-score-and-how-is-it-determined':
//       return 'credit-guide/what-is-a-credit-score-and-how-is-it-determined';
//     case 'learn/credit/what-is-a-credit-utilization-ratio-and-why-does-it-matter':
//       return 'credit-guide/what-is-a-credit-utilization-ratio-and-why-does-it-matter';
//     case 'learn/credit/what-is-a-good-credit-score':
//       return 'credit-guide/what-is-a-good-credit-score';
//     case 'learn/credit/what-is-bankruptcy-and-how-does-it-affect-my-credit':
//       return 'credit-guide/what-is-bankruptcy-and-how-does-it-affect-my-credit';
//     case 'learn/credit/what-is-considered-bad-credit':
//       return 'credit-guide/what-is-considered-bad-credit';
//     default:
//       return 'credit-score?qlsource=rhqdomain';
//   }
// }
